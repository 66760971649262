import { derived, writable } from "svelte/store";

// types
import type { NewsArticle, NewsArticleRaw } from "$types/types";

// utils
import { formatDate, parseRichText } from "$utils/utils";

// data
import { default as news } from "$data/news.json";
const newsData = news as NewsArticleRaw[];

// raw store
export const newsStoreRaw = writable(newsData);

// derived store
export const newsStore = derived(newsStoreRaw, ($newsStoreRaw) => {
  const news = $newsStoreRaw.map((article) => {
    const date = new Date(article.date);
    const body = article.body;
    const formattedBody = parseRichText(body);
    article.body = formattedBody;
    return {
      ...article,
      formattedDate: article.date ? formatDate(date) : "",
    } as NewsArticle;
  });

  return news;
});

// derived slug store
export const newsSlugsStore = derived(newsStoreRaw, ($newsStoreRaw) => {
  return $newsStoreRaw.map((article) => article.slug);
});
