/** @type {import('@sveltejs/kit').ParamMatcher} */
// url matcher for news articles
// read more: https://kit.svelte.dev/docs/advanced-routing#matching

// import news slugs store
import { newsSlugsStore } from "$stores/newsStores";

// unpack slugs
import { get } from "svelte/store";
const slugs = get(newsSlugsStore);

// match url param to valid news article slug
// throw 404 if not found
export function match(param) {
  return slugs.includes(param) ? true : false;
}
