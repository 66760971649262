export function formatDate(date: Date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${month}.${day}.${year}`;
}

// parse rich text
export function parseRichText(string: string) {
  let output = string;

  //- add margins below paragraph
  output = output.replace(/<p>/gi, '<p class="mb-4">');

  //- lists
  output = output.replace(/<ul>/gi, '<ul class="list-disc pl-4 mb-8">');
  output = output.replace(/<li>/gi, '<li class="mb-3 text-[.95em]">');

  //- tables
  output = output.replace(
    /<table>/gi,
    '<table class="table-auto border-collapse mb-4">',
  );
  output = output.replace(
    /<td>/gi,
    '<td class="px-2 py-3 border border-black text-[13px] leading-snug align-top lg:text-sm">',
  );

  //- style h2 tag
  output = output.replace(
    /<h2>/gi,
    '<h2 class="mt-8 mb-8 font-medium text-2xl">',
  );

  //- style h3 tag
  output = output.replace(
    /<h3>/gi,
    '<h3 class="mt-6 mb-4 font-medium text-lg">',
  );

  //- style h4 tag
  output = output.replace(/<h4>/gi, '<h4 class="mb-2 sm:text-lg">');

  //- style h5 tag
  output = output.replace(/<h5>/gi, '<h5 class="mb-2 text-16">');

  //- style anchor tags
  output = output.replace(
    /<a/gi,
    `<a class="
      decoration-current/70
      opacity-90

      underline-offset-4
      focus:outline-appleGreen
      focus:outline-offset-4
      focus:no-underline
      focus:text-appleGreen
      focus:decoration-current
      hover:text-appleGreen
      hover:decoration-current
      hover:opacity-100
      hover:underline
       transition-all"`,
  );

  //- replace <strong>
  output = output.replace(/<strong>/gi, '<div class="font-semibold">');
  output = output.replace(/<\/strong>/gi, "</div>");

  //- remove extraneous non-breaking spaces
  output = output.replace(/\s{2,}/gi, " ");

  return output;
}

// mobile nav and focus-trapping utilities
export { setFocusOnFirstMobileNavLink } from "$stores/navStore";
export { setFocusOnLastMobileNavLink } from "$stores/navStore";
export {
  closeMobileNav,
  toggleMobileNav,
  setFocusOnHamburger,
  setFocusOnBrandLink,
} from "$stores/navStore";
