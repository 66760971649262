import { handleErrorWithSentry } from "@sentry/sveltekit";
// import { Replay } from "@sentry/sveltekit"; // uncomment if you want to use Session Replay
import * as Sentry from "@sentry/sveltekit";

Sentry.init({
  dsn: "https://6cdeab3eee3845af9b0f2619c0b238e6@o4505247956860928.ingest.sentry.io/4506066892816384",
  tracesSampleRate: 1,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 0.0,

  // If you want to use Session Replay, uncomment the following line
  //integrations: [new Replay()],
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
